import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from '../Button';
import { MEDIA_QUERIES } from '../../utils/breakpoints';

const PlaceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    width: 400px;
    max-width: 100%;
    margin: 16px;
    background-color: white;
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0px rgb(0 0 0 / 14%), 0 1px 3px 0px rgb(0 0 0 / 12%);
    overflow: hidden;

    ${MEDIA_QUERIES.medium} {
        margin: 24px 16px;
    }
`;

const Image = styled.div`
    height: 200px;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const Content = styled.div`
    padding: 16px;
    flex-grow: 1;
`;

const Actions = styled.div`
    padding: 16px;
`;

const Title = styled.p`
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 16px;
`;

const Description = styled.p`
    font-size: 18px;
    margin: 0;
`;

const Place = ({ place }) => {
    const { title, address, image, url } = place;

    return (
        <PlaceWrapper>
            <Image image={image} />
            <Content>
                <Title>{title}</Title>
                <Description>{address}</Description>
            </Content>
            <Actions>
                <Button href={url} target="_blank">
                    En savoir plus
                </Button>
            </Actions>
        </PlaceWrapper>
    );
};

Place.propTypes = {
    place: PropTypes.object,
};

export default Place;
