import React from 'react';
import styled from '@emotion/styled';
import config from '../../config/config';
import Place from './Place';
import { MEDIA_QUERIES } from '../../utils/breakpoints';

const GridWrapper = styled.section`
    margin-bottom: 32px;

    ${MEDIA_QUERIES.tablet} {
        margin-bottom: 64px;
    }
`;

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    padding: 16px;
`;

const PlacesGrid = () => {
    const { places } = config;

    return (
        <GridWrapper>
            <Grid>
                {places.map((place) => (
                    <Place place={place} key={place.title} />
                ))}
            </Grid>
        </GridWrapper>
    );
};

export default PlacesGrid;
